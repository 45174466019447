<template>
  <div class="body-bg">
    <van-image
        width="100%"
        :src="require('../../assets/img/main/main_banner.png')"
    />
    <div class="grid-title"><h3>信息发布</h3></div>
    <vue-seamless-scroll :data="listData" :class-option="classOption" class="seamless-warp">
      <ul class="item">
        <li class="msg" v-for="item in listData" @click="msgDetail(item)">
          <div class="msgHeader">
            <div><span style="color: #666666">发布时间：</span>{{item.release_time | dataFormat }}</div>
            <div><van-tag :type="item.type=='工程信息'?'primary':(item.type=='求购'? 'warning':'success')">{{item.type}}</van-tag></div>
          </div>
          <div class="msgContent"><span style="color: #666666">简介：</span>{{item.introduction}}</div>
          <div><span style="color: #666666">所在地址：</span>{{item.address}}</div>
          <div class="msgPhone"><span style="color: #666666">联系方式：</span>{{item.phone || item.other_contacts}} </div>
        </li>
      </ul>
    </vue-seamless-scroll>
    <div class="grid-title"><h3>旗下业务</h3></div>
    <van-grid :gutter="10" :border="false" :column-num="2" style="height: 100%;padding-bottom: 5rem">

      <van-grid-item @click="toGarageKits">
        <a>
          <div class="business-item">
            <div class="business-text">
              <h6>照片上传</h6>
              <span>定格你的工作瞬间</span>
            </div>
            <van-image
                class="business-icon"
                :src="require('../../assets/img/main/icon/sb1.png')"
            />
          </div>
        </a>
      </van-grid-item>

      <van-grid-item>
        <!--              :to="{name: 'linkPage', params: { url: 'http://www.corszh.com/gzhweb/index.html'}}"-->
        <a href="http://www.corszh.com/gzhweb/index.html">
          <div class="business-item">
            <div class="business-text">
              <h6>CORS账号</h6>
              <span>日、月、年账号</span>
            </div>
            <van-image
                class="business-icon"
                :src="require('../../assets/img/main/icon/icon_cors.png')"
            />
          </div>
        </a>
      </van-grid-item>
      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://maplays.net/recruit/'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>人力招聘</h6>
            <span>招聘、发布平台</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/renli_recruit.png')"
          />
        </div>
      </van-grid-item>

      <van-grid-item @click="toLease">
        <a>
          <div class="business-item">
            <div class="business-text">
              <h6>仪器租赁</h6>
              <span>一站式租赁平台</span>
            </div>
            <van-image
                class="business-icon"
                :src="require('../../assets/img/main/icon/icon_lease.png')"
            />
          </div>
        </a>
      </van-grid-item>

      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://www.rtk.xyz'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>RTK评测</h6>
            <span>仪器多维度评测</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/icon_rtk.png')"
          />
        </div>
      </van-grid-item>
      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://www.dysdpx.com/'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>测量培训</h6>
            <span>专业速成培训</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/icon_train.png')"
          />
        </div>
      </van-grid-item>
      <van-grid-item url="http://m.dy-ch.com/">
        <div class="business-item">
          <div class="business-text">
            <h6>仪器商城</h6>
            <span>一站式服务平台</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/icon_store.png')"
          />
        </div>
      </van-grid-item>
<!--      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://www.cldui.cn/'}}">-->
<!--        <div class="business-item">-->
<!--          <div class="business-text">-->
<!--            <h6>资质查询</h6>-->
<!--            <span>测绘服务平台</span>-->
<!--          </div>-->
<!--          <van-image-->
<!--              class="business-icon"-->
<!--              :src="require('../../assets/img/main/icon/icon_measure.png')"-->
<!--          />-->
<!--        </div>-->
<!--      </van-grid-item>-->

      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://www.qxcors.net/bbs/forum.php'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>工程发布</h6>
            <span>一手工程信息</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/icon_project.png')"
          />
        </div>
      </van-grid-item>

      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://www.qxcors.net/bbs/forum.php'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>二手测量仪器</h6>
            <span>高性价比仪器</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/icon_used.png')"
          />
        </div>
      </van-grid-item>
      <!-- url="http://maplays.net/report" 这里是老报备系统的地址，后面需要删除掉关闭服务的以及客户端 -->
      <van-grid-item to="/share">
        <div class="business-item">
          <div class="business-text">
            <h6>合作经销商</h6>
            <span>经销商相关</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/bb.png')"
          />
        </div>
      </van-grid-item>
      <van-grid-item @click="toClass()">
        <div class="business-item">
          <div class="business-text">
            <h6>资料查询</h6>
            <span>各种彩页</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/icon/zl.png')"
          />
        </div>
      </van-grid-item>

<!--      <van-grid-item @click="seqing()">-->
<!--        <div class="business-item">-->
<!--          <div class="business-text">-->
<!--            <h6>申请表</h6>-->
<!--            <span>测试表单</span>-->
<!--          </div>-->
<!--          <van-image-->
<!--              class="business-icon"-->
<!--              :src="require('../../assets/img/main/icon_biaodan.png')"-->
<!--          />-->
<!--        </div>-->
<!--      </van-grid-item>-->


      <van-grid-item :to="{name: 'linkPage', params: { url: 'http://47.108.134.211/pes/mobile/#/index'}}">
        <div class="business-item">
          <div class="business-text">
            <h6>经销商工具</h6>
            <span>报备相关</span>
          </div>
          <van-image
              class="business-icon"
              :src="require('../../assets/img/main/Reporting_system.png')"
          />
        </div>
      </van-grid-item>


    </van-grid>
  </div>
</template>
<script>


import {Toast} from "vant";
import api from "@/utils/api";

export default {
  data() {
    return {
      listData: []
    }
  },
  methods: {
    toClassification(){
      if (window.localStorage.getItem('token') == null){
        Toast.loading({
          message: '请先登录...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
        // this.$router.push({path : 'my?hasLogin'})
      }else {
        this.$router.push({path : '/classification'})
      }
    },
    toLease(goto){
      if(goto==1){
        if (window.localStorage.getItem('token') == null){
          Toast.loading({
            message: '请先登录...',
            forbidClick: true,
          });
          window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
          // this.$router.push({path : 'my?hasLogin'})
        }else {
          this.$router.push({path : '/classification'})
        }
      }
   if (window.localStorage.getItem('token') == null){
        Toast.loading({
          message: '请先登录...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
        // this.$router.push({path : 'my?hasLogin'})
      }else {
        this.$router.push({path : '/lease'})
      }
    },
    toClass(){
      this.$router.push({path : '/classification',query:{flag:false}})
     if (window.localStorage.getItem('token') == null){
        Toast.loading({
          message: '请先登录...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
        // this.$router.push({path : 'my?hasLogin'})
      }else {
        this.$router.push({path : '/classification'})
      }
    },
    toGarageKits(){
      this.$router.push({path : '/shouban'})
      if (window.localStorage.getItem('token') == null){
        Toast.loading({
          message: '请先登录...',
          forbidClick: true,
        });
        window.location.href = 'http://maplays.net/mobile/api/weiXinLogin'
        // this.$router.push({path : 'my?hasLogin'})
      }else {
        this.$router.push({path : '/shouban'})
      }
    },
    async  getData(){
      const {data}=await api.getSelect()
      this.listData=data
    },
    msgDetail(item){
      this.$router.push({name:'msgDetail',params:{data:item}})
    },
    seqing(){ //申请表
      this.$router.push({name:'applicationForm'})
    },
    goBaoBei(){

    }
  },
  mounted() {
    this.getData()
  },
  computed:{
    classOption () {
      return {
        hoverStop: false,
        step:0.2,
        limitMoveNum:this.listData.length
      }
    }
  }
}


// 假登录写这里
//window.localStorage.setItem('token','eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJUZXN0VGV4dCI6WyLov5nph4zmmK_mtYvor5Xph4zpnaLliqDlhaXnmoTnibnmrormlbDmja7vvIEiLCLnjovkvbPmtYvor5XvvIzlnKhhdXRo5o6I5p2D5Lit5b-D6YeM6Z2i77yBIl0sImF1ZCI6WyJtYXBsYXlzLW1vYmlsZS1jbGllbnQiLCJtYXBsYXlzLWF1dGhjZW50ZXIiLCJtYXBsYXlzLXVzZXItc2VydmVyIiwibWFwbGF5cy1ndWFyYW50ZWUtc2VydmVyIl0sInVzZXJfbmFtZSI6IjEzNDU4NjA5NDUwIiwic2NvcGUiOlsibGxsempkIiwidXNlciIsInZzZXJ2aWNlIiwiY29tbW9uIl0sImV4cCI6MTcxMjIxNzUyMiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9jb21tb24iLCJjb21tb246dXNlciJdLCJqdGkiOiI5NzY0MDk3Zi05ZmY5LTQxNjYtOGMzNC02NTE0YmU4NWU1ZjkiLCJjbGllbnRfaWQiOiJtYXBsYXlzIn0.LX1kbrO8V8OnEgQPtsSAAHGk8_hnyhnBlhUO0GiCxvMsf_iImoU2WJEgHGeZQDxPo3-BV2C2TicjPS4U-roQhpuZwck4zt2MV0tDwUJtS_OKUXMBwDc9EffiN-ibOOZGc2s7iz4h9guNcbQ9g2zvh-SpPZbh4nWlY561eGKtrSXaS16wuctUgd9byWdA0vDxMl4UrLYv3bS-Iw36sWDLGTsHKVlN3risZNgduIaVPJlm4yxLaZdJeOlRXLto0TpE8XctxeD-6zGB3T9btfNo7oWgniVnzqGKHExrcGUs3ryOS9YOqGUKD-Fk_3_YT6fjOn9JTCGiKAGKBclc6yYS9A')


</script>
<style lang="less" scoped>

.grid-title {
  font-size: 36px;
  font-weight: bold;
  color: #333333;
  position: relative;
  margin: 20px 0 30px;
  padding: 0 20px;
}

.grid-title::before {
  width: 116px;
  height: 8px;
  background: #FDDB46;

  position: absolute;
  bottom: 4px;
  content: "";
  left: 30px;
}

.grid-title h3 {
  position: relative;
}

.business-item {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: 100%;

  .business-icon {
    width: 120px;
  }

  .business-text {
    -webkit-flex: 1;
    flex: 1;
    margin-right: 5px;

    h6 {
      font-size: 30px;
      color: #333333;
    }

    span {
      color: #666666;
      font-size: 26px;
      display: flex;
      margin-top: 0.3rem;
    }
  }
}

.van-grid-item /deep/ .van-grid-item__content {
  border-radius: 10px !important;
}

.seamless-warp {
  height: 229px;
  overflow: hidden;
  margin: 10px 0px;
}
.msg{
  margin: 15px;
  border-bottom: 1px solid rgba(0,0,0,0.2);
  font-size: 0.3rem;
  padding: 25px 20px;
  background: white;
  border-radius: 10px;
  margin-bottom: 10px;
  color: rgba(0,0,0,0.8);
}
.msg .msgHeader{
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.2rem;

}
.msgContent{
  width: 8rem;
  overflow: hidden;
  white-space: nowrap; // 默认不换行；
  text-overflow: ellipsis;
  margin-bottom: 0.2rem;

}
.msgPhone{
  margin-top: 0.2rem;
}
</style>
